import clsx from "clsx"
import React, { ButtonHTMLAttributes } from "react"

export const Button: React.FC<
  ButtonHTMLAttributes<any> & {
    fluid?: boolean
    theme?: "solid" | "outline"
    size?: "small" | "normal"
  }
> = props => {
  const theme = props.theme || ("solid" as "solid")
  const size = props.size || ("normal" as "normal")

  return (
    <button
      {...props}
      className={clsx(
        `font-display rounded-full border  outline-none transition-all ease-in-out duration-300`,
        props.fluid && "w-full",
        theme === "outline" &&
          "text-slate-900 bg-white border-slate-200 hover:bg-slate-100",
        theme === "solid" &&
          "bg-slate-900 text-white border-slate-900 hover:bg-slate-700",
        size === "small" && "px-3 py-2 text-sm",
        size === "normal" && "py-3 px-6",
        props.className
      )}
    >
      {props.children}
    </button>
  )
}
