export const getHomePageUrl = (lang: string) => {
  if (lang === "en") return "/"
  return `/${lang}/`
}

export const getSearchUrl = (lang: string) => {
  if (lang === "en") return "/search/"
  if (lang === "fr") return "/fr/rechercher/"
  if (lang === "es") return "/es/buscar/"
  if (lang === "pt") return "/pt/pesquisar/"
  if (lang === "it") return "/it/ricercar/"

  throw new Error(`${lang} is an unknown language`)
}

export const getSearchAuthorsUrl = (lang: string) => {
  if (lang === "en") return "/search/authors/"
  if (lang === "fr") return "/fr/rechercher/auteurs/"
  if (lang === "es") return "/es/buscar/autores/"
  if (lang === "pt") return "/pt/pesquisar/autores/"
  if (lang === "it") return "/it/ricercar/autori/"

  throw new Error(`${lang} is an unknown language`)
}

export const getSearchCategoriesUrl = (lang: string) => {
  if (lang === "en") return "/search/categories/"
  if (lang === "fr") return "/fr/rechercher/categories/"
  if (lang === "es") return "/es/buscar/categorias/"
  if (lang === "pt") return "/pt/pesquisar/categorias/"
  if (lang === "it") return "/it/ricercar/categorie/"

  throw new Error(`${lang} is an unknown language`)
}

export const getPopularUrl = (lang: string) => {
  if (lang === "en") return "/popular/"
  if (lang === "fr") return "/fr/populaire/"
  if (lang === "es") return "/es/popular/"
  if (lang === "pt") return "/pt/popular/"
  if (lang === "it") return "/it/popolare/"

  throw new Error(`${lang} is an unknown language`)
}

export const getPrivacyUrl = (lang: string) => {
  if (lang === "en") return "/legal/privacy/"
  if (lang === "fr") return "/fr/legal/confidentialite/"
  if (lang === "es") return "/es/legal/privacidad/"
  if (lang === "pt") return "/pt/legal/privacidade/"
  if (lang === "it") return "/it/legale/privacy/"

  throw new Error(`${lang} is an unknown language`)
}

export const getTermsUrl = (lang: string) => {
  if (lang === "en") return "/legal/terms-of-service/"
  if (lang === "fr") return "/fr/legal/conditions-utilisation/"
  if (lang === "es") return "/es/legal/condiciones-de-uso/"
  if (lang === "pt") return "/pt/legal/termos-de-utilizacao/"
  if (lang === "it") return "/it/legale/termini-di-servizio/"

  throw new Error(`${lang} is an unknown language`)
}

export const getCookiesUrl = (lang: string) => {
  if (lang === "en") return "/legal/cookies/"
  if (lang === "fr") return "/fr/legal/cookies/"
  if (lang === "es") return "/es/legal/cookies/"
  if (lang === "pt") return "/pt/legal/cookies/"
  if (lang === "it") return "/it/legale/cookies/"

  throw new Error(`${lang} is an unknown language`)
}

export const normalizeI18nUrl = (lang: string, url: string) => {
  if (lang === "en") return `/${url}/`
  return `/${lang}/${url}/`
}

export const getAuthorUrl = (lang: string, username: string) => {
  return normalizeI18nUrl(lang, "@" + username)
}

export const getBookmarksUrl = (lang: string) => {
  if (lang === "en") return "/bookmarks/"
  if (lang === "fr") return "/fr/favoris/"
  if (lang === "es") return "/es/favoritos/"
  if (lang === "pt") return "/pt/favoris/"
  if (lang === "it") return "/it/preferiti/"

  throw new Error(`${lang} is an unknown language`)
}

export const getBookmarksLikesUrl = (lang: string) => {
  if (lang === "en") return "/bookmarks/likes/"
  if (lang === "fr") return "/fr/favoris/aimer/"
  if (lang === "es") return "/es/favoritos/gustados/"
  if (lang === "pt") return "/pt/favoris/apreciados/"
  if (lang === "it") return "/it/preferiti/piaciuti/"

  throw new Error(`${lang} is an unknown language`)
}

export const getBookmarksFollowedUrl = (lang: string) => {
  if (lang === "en") return "/bookmarks/followed/"
  if (lang === "fr") return "/fr/favoris/suivis/"
  if (lang === "es") return "/es/favoritos/seguido/"
  if (lang === "pt") return "/pt/favoris/seguido/"
  if (lang === "it") return "/it/preferiti/seguito/"

  throw new Error(`${lang} is an unknown language`)
}
