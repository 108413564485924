import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import {
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  setDoc,
} from "firebase/firestore"
import {
  GetAllCommentsResponse,
  StoreCommentResponse,
  ICommentsRepository,
} from "../interfaces/ICommentsRepository"
import { CommentEntity } from "../entities/CommentEntity"

export class FirebaseCommentsRepository
  extends FirebaseUtils
  implements ICommentsRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  async getAll(articleId: string): Promise<GetAllCommentsResponse> {
    const app = this.firebase.firestore()
    const elements = await getDocs(
      query(
        collection(app, `articles/${articleId}/comments`),
        orderBy("publishedAt", "desc")
      )
    )

    const comments = this.mapQuerySnapshot<CommentEntity>(elements)

    return {
      comments: comments.map(comment => ({
        ...comment,
        // @ts-ignore
        publishedAt: comment.publishedAt.toDate(),
      })),
      error: false,
    }
  }

  async store(comment: CommentEntity): Promise<StoreCommentResponse> {
    const app = this.firebase.firestore()

    const articlesCollection = doc(app, "articles", comment.articleId)
    const followsCollection = doc(articlesCollection, "comments", comment.id)

    await setDoc(followsCollection, comment)

    return {
      error: false,
      body: comment,
    }
  }
}
