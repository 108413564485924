import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import {
  collection,
  doc,
  getDocs,
  deleteDoc,
  addDoc,
  setDoc,
} from "firebase/firestore"
import {
  GetAllLikesResponse,
  ILikesRepository,
  RemoveBookmarkResponse,
  StoreBookmarkResponse,
} from "../interfaces/ILikesRepository"
import { LikeEntity } from "../entities/LikeEntity"

export class FirebaseLikesRepository
  extends FirebaseUtils
  implements ILikesRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  async getAll(userId: string): Promise<GetAllLikesResponse> {
    const app = this.firebase.firestore()
    const elements = await getDocs(collection(app, `users/${userId}/likes`))

    const likes = this.mapQuerySnapshot<LikeEntity>(elements)

    return {
      likes,
      error: false,
    }
  }

  async store(params: {
    userId: string
    articleId: string
  }): Promise<StoreBookmarkResponse> {
    const app = this.firebase.firestore()

    const usersCollection = doc(app, "users", params.userId)
    const likesCollection = doc(usersCollection, "likes", params.articleId)

    await setDoc(likesCollection, {
      id: params.articleId,
    })

    return {
      error: false,
    }
  }

  async remove(params: {
    userId: string
    articleId: string
  }): Promise<RemoveBookmarkResponse> {
    const app = this.firebase.firestore()
    const usersCollection = doc(app, "users", params.userId)
    const likesCollection = doc(usersCollection, "likes", params.articleId)

    await deleteDoc(likesCollection)

    return {
      error: false,
    }
  }
}
