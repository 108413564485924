import { IModule } from "../interfaces/IModule"
import { InMemoryAuthRepository } from "../repositories/InMemoryAuthRepository"
import { InMemoryBookmarksRepository } from "../repositories/InMemoryBookmarksRepository"
import { InMemoryCommentsRepository } from "../repositories/InMemoryCommentsRepository"
import { InMemoryFollowsRepository } from "../repositories/InMemoryFollowsRepository"
import { InMemoryLikesRepository } from "../repositories/InMemoryLikesRepository"
import { InMemoryLocationService } from "../services/InMemoryLocationService"

export class TestModule implements IModule {
  build() {
    const AuthRepository = new InMemoryAuthRepository()
    const LocationService = new InMemoryLocationService()
    const BookmarksRepository = new InMemoryBookmarksRepository()
    const LikesRepository = new InMemoryLikesRepository()
    const FollowsRepository = new InMemoryFollowsRepository()
    const CommentsRepository = new InMemoryCommentsRepository()

    return {
      AuthRepository,
      LocationService,
      BookmarksRepository,
      LikesRepository,
      FollowsRepository,
      CommentsRepository
    }
  }
}
