import { navigate } from "gatsby"

export const openLoginModal = () => {
  window.location.href = window.location.href + "#login=true"
}

export const closeLoginModal = () => {
  navigate(-1)
}

export const openLogoutModal = () => {
  window.location.href = window.location.href + "#logout=true"
}

export const closeLogoutModal = () => {
  navigate(-1)
}
