import { FollowEntity } from "../../entities/FollowEntity"

export const store = "REDUX_Follow_STORE"
export const storeAll = "REDUX_Follow_STORE_ALL"
export const remove = "REDUX_Follow_REMOVE"

export interface storeAllAction {
  type: typeof storeAll
  payload: FollowEntity[]
}

export interface storeAction {
  type: typeof store
  payload: FollowEntity
}

export interface removeAction {
  type: typeof remove
  payload: { id: FollowEntity["id"] }
}

export type FollowActionTypes = storeAction | removeAction | storeAllAction
