import { UserEntity } from "../../entities/UserEntity"
import * as constants from "./types"

type AuthState = {
  user: UserEntity | null
  isConnected: boolean
  fetching: boolean
}

const initialState: AuthState = {
  user: null,
  isConnected: false,
  fetching: false,
}

export function authReducer(
  state = initialState,
  action: constants.AuthActionTypes
): AuthState {
  if (action.type === constants.authenticate) {
    return {
      ...state,
      isConnected: true,
      user: action.payload.user,
    }
  }

  if (action.type === constants.logout) {
    return { ...initialState }
  }

  if (action.type === constants.fetching) {
    return {
      ...state,
      fetching: true,
    }
  }

  if (action.type === constants.fetchEnd) {
    return {
      ...state,
      fetching: false,
    }
  }

  return state
}
