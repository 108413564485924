import { IModule } from "../interfaces/IModule"
import { FirebaseAuthRepository } from "../repositories/FirebaseAuthRepository"
import { Firebase } from "../services/firebase"
import { GatsbyLocationService } from "../services/GatsbyLocationService"
import { FirebaseBookmarksRepository } from "../repositories/FirebaseBookmarksRepository"
import { FirebaseLikesRepository } from "../repositories/FirebaseLikesRepository"
import { FirebaseFollowsRepository } from "../repositories/FirebaseFollowsRepository"
import { FirebaseCommentsRepository } from "../repositories/FirebaseCommentsRepository"

export class DevelopmentModule implements IModule {
  build() {
    const firebase = new Firebase()

    const AuthRepository = new FirebaseAuthRepository(firebase)
    const LocationService = new GatsbyLocationService()
    const BookmarksRepository = new FirebaseBookmarksRepository(firebase)
    const LikesRepository = new FirebaseLikesRepository(firebase)
    const FollowsRepository = new FirebaseFollowsRepository(firebase)
    const CommentsRepository = new FirebaseCommentsRepository(firebase)

    return {
      AuthRepository,
      LocationService,
      BookmarksRepository,
      LikesRepository,
      FollowsRepository,
      CommentsRepository,
    }
  }
}
