import { BookmarkEntity } from "../entities/BookmarkEntity"
import {
  GetAllBookmarksResponse,
  IBookmarksRepository,
  RemoveBookmarkResponse,
  StoreBookmarkResponse,
} from "../interfaces/IBookmarksRepository"

export class InMemoryBookmarksRepository implements IBookmarksRepository {
  private bookmarks: Map<BookmarkEntity["id"], BookmarkEntity> = new Map()

  async getAll(userId: string | null): Promise<GetAllBookmarksResponse> {
    return {
      bookmarks: Array.from(this.bookmarks.values()),
      error: false,
    }
  }

  async store(params: {
    userId: string | null
    articleId: string
  }): Promise<StoreBookmarkResponse> {
    this.bookmarks.set(params.articleId, { id: params.articleId })

    return { error: false }
  }

  async remove(params: {
    userId: string | null
    articleId: string
  }): Promise<RemoveBookmarkResponse> {
    this.bookmarks.delete(params.articleId)

    return { error: false }
  }
}
