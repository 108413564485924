import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"

const mapDispatch = (dispatch: any) => ({
  onMount: () => {
    dispatch(actions.auth.fetchIsAuthenticated()).then(authenticated => {
      if (authenticated) {
        dispatch(actions.bookmarks.$fetchAll())
        dispatch(actions.likes.$fetchAll())
        dispatch(actions.follows.$fetchAll())
      }
    })
  },
})

export const connector = connect(null, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
