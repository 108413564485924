import React from "react"
import { IntlProvider } from "react-intl"

type Props = {
  langKey: string
  children: any
  translations: any
}

export const CustomIntlProvider: React.FC<Props> = props => {
  return (
    <IntlProvider locale={props.langKey} messages={props.translations}>
      {props.children}
    </IntlProvider>
  )
}
