import app from "gatsby-plugin-firebase-v9.0"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

export class Firebase {
  constructor() {}

  firestore() {
    return getFirestore(app)
  }

  auth() {
    return getAuth(app)
  }
}
