import { ThunkAction } from "redux-thunk"
import { openLoginModal } from "../../utils/authModal"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"

export const store = (
  payload: types.storeAction["payload"]
): types.LikeActionTypes => ({
  type: types.store,
  payload,
})

export const storeAll = (
  payload: types.storeAllAction["payload"]
): types.LikeActionTypes => ({
  type: types.storeAll,
  payload,
})

export const remove = (
  payload: types.removeAction["payload"]
): types.LikeActionTypes => ({
  type: types.remove,
  payload,
})

export const $fetchAll =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { likes, di, auth } = getState()

    if (auth.isConnected === false) return

    const response = await di.LikesRepository.getAll(auth.user?.id as string)

    if (response.error === false) {
      dispatcher(storeAll(response.likes))
    }
  }

export const $add =
  (params: { articleId: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { likes, di, auth } = getState()

    try {
      if (auth.isConnected === false) {
        return openLoginModal()
      }

      await di.LikesRepository.store({
        userId: auth.user?.id as string,
        articleId: params.articleId,
      })

      dispatcher(
        actions.notifications.create({
          message: "notifications/like",
          type: "success",
        })
      )

      dispatcher(store({ id: params.articleId }))
    } catch (e) {
      console.log(e)
    }
  }

export const $toggle =
  (params: { articleId: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { likes, di } = getState()

    if (likes.likes.has(params.articleId)) return dispatcher($remove(params))
    return dispatcher($add(params))
  }

export const $remove =
  (params: { articleId: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, auth } = getState()
    try {
      if (auth.isConnected === false) {
        return openLoginModal()
      }

      await di.LikesRepository.remove({
        userId: auth.user?.id as string,
        articleId: params.articleId,
      })

      dispatcher(
        actions.notifications.create({
          message: "notifications/unlike",
          type: "success",
        })
      )

      dispatcher(remove({ id: params.articleId }))
    } catch (e) {}
  }
