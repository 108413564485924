import { UserEntity } from "../entities/UserEntity"

import {
  IAuthRepository,
  IsAuthenticatedReturnType,
  AuthenticateReturnType,
} from "../interfaces/IAuthRepository"

export const users: { [key: string]: UserEntity } = {
  base: {
    id: "1",
    email: "kevin@gmail.com",
    avatar: "avatar",
    username: "username",
  },
  noAvatarAndNoUsername: {
    id: "2",
    email: "no-avatar-and-no-username@gmail.com",
    avatar: null as unknown as string,
    username: null as unknown as string,
  },
}

export class InMemoryAuthRepository implements IAuthRepository {
  private signinLinkCodes: Array<{
    email: string
    code: string
    destinationUrl: string
  }> = []
  private users: Array<UserEntity> = [users.base, users.noAvatarAndNoUsername]
  private authenticated: UserEntity | null = null

  async logout() {
    this.authenticated = null
    return { succeed: true }
  }

  setAuthenticated(email?: string) {
    if (!email) this.authenticated = users.base
    else
      this.authenticated = this.users.find(
        user => user.email === email
      ) as UserEntity
  }

  authenticate(): Promise<AuthenticateReturnType> {
    throw new Error("Method not implemented.")
  }

  async isAuthenticated(): Promise<IsAuthenticatedReturnType> {
    if (!this.authenticated) return { authenticated: false }

    return { authenticated: true, user: this.authenticated }
  }

  async sendSignInLinkToEmail(params: {
    email: string
    lang: string
    destinationUrl: string
  }): Promise<SendSignInLinkToEmailType> {
    if (this.sendSignInLinkToEmailError)
      return {
        succeed: false,
        error: this.sendSignInLinkToEmailError,
      }

    if (params.email === "error-server@gmail.com")
      return {
        succeed: false,
        error: "ERROR_SERVER",
      }

    if (params.email === "invalid-email@gmail.com")
      return {
        succeed: false,
        error: "INVALID_EMAIL",
      }

    this.signinLinkCodes.push({
      email: params.email,
      code: "test-auth-code",
      destinationUrl: params.destinationUrl,
    })

    return {
      succeed: true,
    }
  }
}
