import { LikeEntity } from "../../entities/LikeEntity"
import * as types from "./types"

interface SnackState {
  likes: Map<LikeEntity["id"], LikeEntity>
}

const initialState: SnackState = {
  likes: new Map(),
}

export function likesReducer(
  state = initialState,
  action: types.LikeActionTypes
): SnackState {
  if (action.type === types.store) {
    state.likes.set(action.payload.id, action.payload)
    return {
      ...state,
    }
  }

  if (action.type === types.storeAll) {
    return {
      ...state,
      likes: new Map(action.payload.map(bookmark => [bookmark.id, bookmark])),
    }
  }

  if (action.type === types.remove) {
    state.likes.delete(action.payload.id)
    return { ...state }
  }

  return state
}
