import { FollowEntity } from "../entities/FollowEntity"
import {
  GetAllFollowsResponse,
  IFollowsRepository,
  RemoveFollowResponse,
  StoreFollowResponse,
} from "../interfaces/IFollowsRepository"

export class InMemoryFollowsRepository implements IFollowsRepository {
  private follows: Map<FollowEntity["id"], FollowEntity> = new Map()

  async getAll(userId: string | null): Promise<GetAllFollowsResponse> {
    return {
      follows: Array.from(this.follows.values()),
      error: false,
    }
  }

  async store(params: {
    userId: string | null
    authorId: string
  }): Promise<StoreFollowResponse> {
    this.follows.set(params.authorId, { id: params.authorId })

    return { error: false }
  }

  async remove(params: {
    userId: string | null
    authorId: string
  }): Promise<RemoveFollowResponse> {
    this.follows.delete(params.authorId)

    return { error: false }
  }
}
