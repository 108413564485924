import { Firebase } from "../services/firebase"
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
} from "firebase/auth"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import {
  AuthenticateReturnErrorType,
  AuthenticateReturnType,
  IAuthRepository,
  IsAuthenticatedReturnType,
} from "../interfaces/IAuthRepository"

export class FirebaseAuthRepository
  extends FirebaseUtils
  implements IAuthRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  async isAuthenticated(): Promise<IsAuthenticatedReturnType> {
    return new Promise((resolve, reject) => {
      const firebase = this.firebase.auth()

      onAuthStateChanged(firebase, function (user) {
        if (user)
          return resolve({
            authenticated: true,
            user: {
              id: user.uid,
              email: user.email as string,
              avatar: user.photoURL || "",
              username: user.displayName,
            },
          })
        return resolve({ authenticated: false })
      })
    })
  }

  async logout() {
    try {
      const firebase = this.firebase.auth()
      signOut(firebase)
      return { succeed: true }
    } catch (e) {
      return { succeed: true }
    }
  }

  async authenticate(): Promise<AuthenticateReturnType> {
    const firebase = this.firebase.auth()
    const provider = new GoogleAuthProvider()

    try {
      const response = await signInWithPopup(firebase, provider)

      return {
        authenticated: true,
        user: {
          id: response.user?.uid as string,
          email: response.user?.email as string,
          avatar: response.user?.photoURL || "null",
          username: response.user?.displayName || null,
        },
      }
    } catch (e) {
      const error = e as {
        code:
          | "auth/expired-action-code"
          | "auth/invalid-email"
          | "auth/user-disabled"
      }

      const codes: {
        [key in typeof error.code]: AuthenticateReturnErrorType
      } = {
        "auth/user-disabled": "USER_DISABLED",
        "auth/expired-action-code": "EMAIL_BAD_FORMATTED",
        "auth/invalid-email": "EMAIL_BAD_FORMATTED",
      }

      return {
        error: codes[error.code] || "EMAIL_BAD_FORMATTED",
        authenticated: false,
      }
    }
  }
}
