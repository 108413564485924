import { FollowEntity } from "../../entities/FollowEntity"
import * as types from "./types"

interface FollowsState {
  follows: Map<FollowEntity["id"], FollowEntity>
}

const initialState: FollowsState = {
  follows: new Map(),
}

export function followsReducer(
  state = initialState,
  action: types.FollowActionTypes
): FollowsState {
  if (action.type === types.store) {
    state.follows.set(action.payload.id, action.payload)
    return {
      ...state,
    }
  }

  if (action.type === types.storeAll) {
    return {
      ...state,
      follows: new Map(action.payload.map(follow => [follow.id, follow])),
    }
  }

  if (action.type === types.remove) {
    state.follows.delete(action.payload.id)
    return { ...state }
  }

  return state
}
