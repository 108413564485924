import { BookmarkEntity } from "../entities/BookmarkEntity"
import {
  GetAllLikesResponse,
  ILikesRepository,
  RemoveBookmarkResponse,
  StoreBookmarkResponse,
} from "../interfaces/ILikesRepository"

export class InMemoryLikesRepository implements ILikesRepository {
  private likes: Map<BookmarkEntity["id"], BookmarkEntity> = new Map()

  async getAll(userId: string | null): Promise<GetAllLikesResponse> {
    return {
      likes: Array.from(this.likes.values()),
      error: false,
    }
  }

  async store(params: {
    userId: string | null
    articleId: string
  }): Promise<StoreBookmarkResponse> {
    this.likes.set(params.articleId, { id: params.articleId })

    return { error: false }
  }

  async remove(params: {
    userId: string | null
    articleId: string
  }): Promise<RemoveBookmarkResponse> {
    this.likes.delete(params.articleId)

    return { error: false }
  }
}
