import * as auth from "./auth/actions"
import * as di from "./di/actions"
import * as snack from "./snack/actions"
import * as notifications from "./notifications/actions"
import * as bookmarks from "./bookmarks/actions"
import * as likes from "./likes/actions"
import * as follows from "./follows/actions"
import * as comments from "./comments/actions"

export const actions = {
  auth,
  snack,
  di,
  notifications,
  bookmarks,
  likes,
  follows,
  comments,
}
