import { ThunkAction } from "redux-thunk"
import { openLoginModal } from "../../utils/authModal"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"

export const store = (
  payload: types.storeAction["payload"]
): types.FollowActionTypes => ({
  type: types.store,
  payload,
})

export const storeAll = (
  payload: types.storeAllAction["payload"]
): types.FollowActionTypes => ({
  type: types.storeAll,
  payload,
})

export const remove = (
  payload: types.removeAction["payload"]
): types.FollowActionTypes => ({
  type: types.remove,
  payload,
})

export const $fetchAll =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, auth } = getState()

    if (auth.isConnected === false) return

    const response = await di.FollowsRepository.getAll(auth.user?.id as string)

    if (response.error === false) {
      dispatcher(storeAll(response.follows))
    }
  }

export const $add =
  (params: { authorId: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { follows, di, auth } = getState()

    try {
      if (auth.isConnected === false) {
        return openLoginModal()
      }

      await di.FollowsRepository.store({
        userId: auth.user?.id as string,
        authorId: params.authorId,
      })

      dispatcher(
        actions.notifications.create({
          message: "notifications/follow",
          type: "success",
        })
      )

      dispatcher(store({ id: params.authorId }))
    } catch (e) {
      console.log(e)
    }
  }

export const $toggle =
  (params: { authorId: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { follows, di } = getState()

    if (follows.follows.has(params.authorId)) return dispatcher($remove(params))
    return dispatcher($add(params))
  }

export const $remove =
  (params: { authorId: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { follows, di, auth } = getState()
    try {
      if (auth.isConnected === false) {
        return openLoginModal()
      }

      await di.FollowsRepository.remove({
        userId: auth.user?.id as string,
        authorId: params.authorId,
      })

      dispatcher(
        actions.notifications.create({
          message: "notifications/unfollow",
          type: "success",
        })
      )

      dispatcher(remove({ id: params.authorId }))
    } catch (e) {}
  }
