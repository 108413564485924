import { createStore, applyMiddleware, combineReducers } from "redux"
import thunk from "redux-thunk"
import { actions } from "./actions"
import { diReducer } from "./di/reducers"
import { DiInjectable } from "./di/types"
import { authReducer } from "./auth/reducers"
import { snackReducer } from "./snack/reducers"
import { notificationsReducer } from "./notifications/reducers"
import { bookmarksReducer } from "./bookmarks/reducers"
import { likesReducer } from "./likes/reducers"
import { followsReducer } from "./follows/reducers"
import { commentsReducer } from "./comments/reducers"

const enhancer = applyMiddleware(thunk)

export const reducers = combineReducers({
  di: diReducer,
  auth: authReducer,
  snack: snackReducer,
  notifications: notificationsReducer,
  bookmarks: bookmarksReducer,
  likes: likesReducer,
  follows: followsReducer,
  comments: commentsReducer,
})

export type RootState = ReturnType<typeof reducers>

export const init = (initialState = {}, di: DiInjectable) => {
  const store = createStore(reducers, initialState, enhancer)

  store.dispatch(actions.di.register(di))

  return { store }
}
