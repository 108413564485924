import { BookmarkEntity } from "../../entities/BookmarkEntity"

export const store = "REDUX_Bookmark_STORE"
export const storeAll = "REDUX_Bookmark_STORE_ALL"
export const remove = "REDUX_Bookmark_REMOVE"

export interface storeAllAction {
  type: typeof storeAll
  payload: BookmarkEntity[]
}

export interface storeAction {
  type: typeof store
  payload: BookmarkEntity
}

export interface removeAction {
  type: typeof remove
  payload: { id: BookmarkEntity["id"] }
}

export type BookmarkActionTypes = storeAction | removeAction | storeAllAction
