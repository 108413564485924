import { LikeEntity } from "../../entities/LikeEntity"

export const store = "REDUX_Like_STORE"
export const storeAll = "REDUX_Like_STORE_ALL"
export const remove = "REDUX_Like_REMOVE"

export interface storeAllAction {
  type: typeof storeAll
  payload: LikeEntity[]
}

export interface storeAction {
  type: typeof store
  payload: LikeEntity
}

export interface removeAction {
  type: typeof remove
  payload: { id: LikeEntity["id"] }
}

export type LikeActionTypes = storeAction | removeAction | storeAllAction
