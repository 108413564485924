import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import {
  collection,
  doc,
  getDocs,
  deleteDoc,
  addDoc,
  setDoc,
} from "firebase/firestore"
import {
  GetAllBookmarksResponse,
  IBookmarksRepository,
  RemoveBookmarkResponse,
  StoreBookmarkResponse,
} from "../interfaces/IBookmarksRepository"
import { BookmarkEntity } from "../entities/BookmarkEntity"

export class FirebaseBookmarksRepository
  extends FirebaseUtils
  implements IBookmarksRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  async getAll(userId: string): Promise<GetAllBookmarksResponse> {
    const app = this.firebase.firestore()
    const elements = await getDocs(collection(app, `users/${userId}/bookmarks`))


    const bookmarks = this.mapQuerySnapshot<BookmarkEntity>(elements)

    return {
      bookmarks,
      error: false,
    }
  }

  async store(params: {
    userId: string
    articleId: string
  }): Promise<StoreBookmarkResponse> {
    const app = this.firebase.firestore()

    const usersCollection = doc(app, "users", params.userId)
    const bookmarksCollection = doc(
      usersCollection,
      "bookmarks",
      params.articleId
    )

    await setDoc(bookmarksCollection, {
      id: params.articleId,
    })

    return {
      error: false,
    }
  }

  async remove(params: {
    userId: string
    articleId: string
  }): Promise<RemoveBookmarkResponse> {
    const app = this.firebase.firestore()
    const usersCollection = doc(app, "users", params.userId)
    const bookmarksCollection = doc(
      usersCollection,
      "bookmarks",
      params.articleId
    )

    await deleteDoc(bookmarksCollection)

    return {
      error: false,
    }
  }
}
