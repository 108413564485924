import { ArticleEntity } from "../../entities/ArticleEntity"
import { CommentEntity } from "../../entities/CommentEntity"
import * as types from "./types"

interface CommentsState {
  comments: Array<CommentEntity>
  fetching: boolean
  isOpen: boolean
  articleId: ArticleEntity["id"] | null
  value: string
}

const initialState: CommentsState = {
  comments: [],
  fetching: false,
  isOpen: false,
  articleId: null,
  value: "",
}

export function commentsReducer(
  state = initialState,
  action: types.CommentActionTypes
): CommentsState {
  if (action.type === types.add) {
    return {
      ...state,
      comments: [action.payload.comment, ...state.comments],
    }
  }

  if (action.type === types.update) {
    return {
      ...state,
      value: action.payload.value,
    }
  }

  if (action.type === types.storeAll) {
    return {
      ...state,
      comments: action.payload.comments,
    }
  }

  if (action.type === types.setFetching) {
    return {
      ...state,
      fetching: action.payload.fetching,
    }
  }

  if (action.type === types.setOpen) {
    return {
      ...state,
      articleId: action.payload.articleId,
      isOpen: action.payload.isOpen,
    }
  }

  return state
}
