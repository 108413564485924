import { ArticleEntity } from "../../entities/ArticleEntity"
import { CommentEntity } from "../../entities/CommentEntity"

export const add = "REDUX_Comment_ADD"
export const storeAll = "REDUX_Comment_STORE_ALL"
export const setFetching = "REDUX_Comment_SET_FETCHING"
export const setOpen = "REDUX_Comment_setOpen"

export interface storeAllAction {
  type: typeof storeAll
  payload: { comments: CommentEntity[] }
}


export const update = "REDUX_Comment_update"
export interface updateAction {
  type: typeof update
  payload: { value: string }
}

export interface addAction {
  type: typeof add
  payload: { comment: CommentEntity }
}

export interface setFetchingAction {
  type: typeof setFetching
  payload: { fetching: boolean }
}

export interface setOpenAction {
  type: typeof setOpen
  payload: { isOpen: boolean; articleId: ArticleEntity["id"] }
}

export type CommentActionTypes =
  | addAction
  | setFetchingAction
  | storeAllAction
  | setOpenAction
  | updateAction
