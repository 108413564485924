import { ThunkAction } from "redux-thunk"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"

export const storeAll = (
  payload: types.storeAllAction["payload"]
): types.CommentActionTypes => ({
  type: types.storeAll,
  payload,
})

export const add = (
  payload: types.addAction["payload"]
): types.CommentActionTypes => ({
  type: types.add,
  payload,
})

export const setOpen = (
  payload: types.setOpenAction["payload"]
): types.CommentActionTypes => ({
  type: types.setOpen,
  payload,
})

export const update = (
  payload: types.updateAction["payload"]
): types.CommentActionTypes => ({
  type: types.update,
  payload,
})

export const $add =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, auth, comments } = getState()

    try {
      if (auth.isConnected === false) {
        await dispatcher(actions.auth.fetchAuthenticateWithGoogle())
        if (!getState().auth.isConnected) return false
      }

      const comment = {
        articleId: comments.articleId as string,
        author: {
          id: auth.user?.id as string,
          image: auth.user?.avatar as string,
          name: auth.user?.username as string,
        },
        id: Date.now().toString(),
        publishedAt: new Date(),
        value: comments.value,
      }

      await di.CommentsRepository.store(comment)

      dispatcher(
        actions.notifications.create({
          message: "notifications/add-comment",
          type: "success",
        })
      )

      dispatcher(add({ comment }))
      dispatcher(update({ value: "" }))
    } catch (e) {
      console.log(e)
    }
  }

export const $fetchAll =
  (articleId: string): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di } = getState()

    const response = await di.CommentsRepository.getAll(articleId)

    if (response.error === false) {
      dispatcher(storeAll({ comments: response.comments }))
    }
  }
