import { BookmarkEntity } from "../../entities/BookmarkEntity"
import * as types from "./types"

interface SnackState {
  bookmarks: Map<BookmarkEntity["id"], BookmarkEntity>
}

const initialState: SnackState = {
  bookmarks: new Map(),
}

export function bookmarksReducer(
  state = initialState,
  action: types.BookmarkActionTypes
): SnackState {
  if (action.type === types.store) {
    state.bookmarks.set(action.payload.id, action.payload)
    return {
      ...state,
    }
  }

  if (action.type === types.storeAll) {
    return {
      ...state,
      bookmarks: new Map(
        action.payload.map(bookmark => [bookmark.id, bookmark])
      ),
    }
  }

  if (action.type === types.remove) {
    state.bookmarks.delete(action.payload.id)
    return { ...state }
  }

  return state
}
