import { BookmarkEntity } from "../entities/BookmarkEntity"
import { CommentEntity } from "../entities/CommentEntity"
import {
  GetAllCommentsResponse,
  StoreCommentResponse,
} from "../interfaces/ICommentsRepository"
import { ICommentsRepository } from "../interfaces/ICommentsRepository"

export class InMemoryCommentsRepository implements ICommentsRepository {
  private comments: Map<CommentEntity["id"], CommentEntity> = new Map()

  async getAll(articleId: string): Promise<GetAllCommentsResponse> {
    return {
      comments: Array.from(this.comments.values()),
      error: false,
    }
  }

  async store(comment: CommentEntity): Promise<StoreCommentResponse> {
    this.comments.set(comment.id, comment)

    return { error: false, body: comment }
  }
}
