import React from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import { connector, ContainerProps } from "./containers/LoginModal.container"
import { Button } from "../Button/Button"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Link } from "../Link/Link"
import { getPrivacyUrl, getTermsUrl } from "../../utils/i18nUrls"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { closeLoginModal } from "../../utils/authModal"
import { useIntl } from "react-intl"

type Props = {
  onLogin: () => void
}

export const LogoutModalWrapper: React.FC<Props> = props => {
  const intl = useIntl()
  const location = useLocation()
  const isOpen = location.hash.includes("login=true")

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => false}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 py-8 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-10 sm:py-20">
                <div className="absolute right-0 top-0">
                  <div className="p-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-slate-400 hover:text-slate-500 focus:outline-none"
                      onClick={() => closeLoginModal()}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <div className="w-full">
                  <div className="mt-3 text-center sm:mt-0">
                    <div
                      className="text-xl font-display leading-6 font-medium text-slate-900"
                      id="modal-headline"
                    >
                      <FormattedMessage id="login/modal/title" />
                    </div>

                    <p className="text-slate-500">
                      <FormattedMessage id="login/modal/description" />
                    </p>

                    <Button
                      className="flex items-center mx-auto mt-10"
                      theme={"outline"}
                      onClick={() => props.onLogin()}
                    >
                      <svg width="25" height="25">
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z"
                            fill="#4285F4"
                          ></path>
                          <path
                            d="M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z"
                            fill="#34A853"
                          ></path>
                          <path
                            d="M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z"
                            fill="#FBBC05"
                          ></path>
                          <path
                            d="M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z"
                            fill="#EA4335"
                          ></path>
                        </g>
                      </svg>
                      <span className="ml-2">
                        <FormattedMessage id="login/modal/google" />
                      </span>
                    </Button>

                    <p className="text-slate-500 mt-10 text-sm">
                      <FormattedMessage
                        id="login/modal/privacy"
                        values={{
                          p: values => (
                            <Link
                              url={getPrivacyUrl(intl.locale)}
                              className="underline"
                            >
                              {values}
                            </Link>
                          ),
                          t: values => (
                            <Link
                              url={getTermsUrl(intl.locale)}
                              className="underline"
                            >
                              {values}
                            </Link>
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const LogoutModalContainer: React.FC<ContainerProps> = props => (
  <LogoutModalWrapper {...props} />
)

export const LoginModal = connector(LogoutModalContainer)
