import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import { collection, doc, getDocs, deleteDoc, setDoc } from "firebase/firestore"
import {
  GetAllFollowsResponse,
  IFollowsRepository,
  RemoveFollowResponse,
  StoreFollowResponse,
} from "../interfaces/IFollowsRepository"
import { FollowEntity } from "../entities/FollowEntity"

export class FirebaseFollowsRepository
  extends FirebaseUtils
  implements IFollowsRepository
{
  constructor(private firebase: Firebase) {
    super()
  }

  async getAll(userId: string): Promise<GetAllFollowsResponse> {
    const app = this.firebase.firestore()
    const elements = await getDocs(collection(app, `users/${userId}/follows`))

    const follows = this.mapQuerySnapshot<FollowEntity>(elements)

    return {
      follows,
      error: false,
    }
  }

  async store(params: {
    userId: string
    authorId: string
  }): Promise<StoreFollowResponse> {
    const app = this.firebase.firestore()

    const usersCollection = doc(app, "users", params.userId)
    const followsCollection = doc(usersCollection, "follows", params.authorId)

    await setDoc(followsCollection, {
      id: params.authorId,
    })

    return {
      error: false,
    }
  }

  async remove(params: {
    userId: string
    authorId: string
  }): Promise<RemoveFollowResponse> {
    const app = this.firebase.firestore()
    const usersCollection = doc(app, "users", params.userId)
    const followsCollection = doc(usersCollection, "follows", params.authorId)

    await deleteDoc(followsCollection)

    return {
      error: false,
    }
  }
}
