import { ThunkAction } from "redux-thunk"
import { openLoginModal } from "../../utils/authModal"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"

export const store = (
  payload: types.storeAction["payload"]
): types.BookmarkActionTypes => ({
  type: types.store,
  payload,
})

export const storeAll = (
  payload: types.storeAllAction["payload"]
): types.BookmarkActionTypes => ({
  type: types.storeAll,
  payload,
})

export const remove = (
  payload: types.removeAction["payload"]
): types.BookmarkActionTypes => ({
  type: types.remove,
  payload,
})

export const $fetchAll =
  (): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { bookmarks, di, auth } = getState()

    if (auth.isConnected === false) return

    const response = await di.BookmarksRepository.getAll(
      auth.user?.id as string
    )

    if (response.error === false) {
      dispatcher(storeAll(response.bookmarks))
    }
  }

export const $add =
  (params: { articleId: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { bookmarks, di, auth } = getState()

    try {
      if (auth.isConnected === false) {
        return openLoginModal()
      }

      await di.BookmarksRepository.store({
        userId: auth.user?.id as string,
        articleId: params.articleId,
      })

      dispatcher(
        actions.notifications.create({
          message: "notifications/bookmark",
          type: "success",
        })
      )

      dispatcher(store({ id: params.articleId }))
    } catch (e) {
      console.log(e)
    }
  }

export const $toggle =
  (params: { articleId: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { bookmarks, di } = getState()

    if (bookmarks.bookmarks.has(params.articleId))
      return dispatcher($remove(params))
    return dispatcher($add(params))
  }

export const $remove =
  (params: { articleId: string }): ThunkAction<void, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { bookmarks, di, auth } = getState()
    try {
      if (auth.isConnected === false) {
        return openLoginModal()
      }

      await di.BookmarksRepository.remove({
        userId: auth.user?.id as string,
        articleId: params.articleId,
      })

      dispatcher(
        actions.notifications.create({
          message: "notifications/unbookmark",
          type: "success",
        })
      )

      dispatcher(remove({ id: params.articleId }))
    } catch (e) {}
  }
