import { ThunkAction, ThunkDispatch } from "redux-thunk"
import { UserEntity, UserWithoutIdEntity } from "../../entities/UserEntity"
import * as types from "./types"
import * as snack from "../snack/actions"
import { RootState } from "../store"
import { actions } from "../actions"
import { navigate } from "gatsby"
import { closeLoginModal } from "../../utils/authModal"

export const authenticate = (
  payload: types.authenticateAction["payload"]
): types.AuthActionTypes => ({
  type: types.authenticate,
  payload,
})

export const logout = (): types.AuthActionTypes => ({
  type: types.logout,
})

export const fetching = (): types.AuthActionTypes => ({
  type: types.fetching,
})

export const fetchEnd = (): types.AuthActionTypes => ({
  type: types.fetchEnd,
})

export const fetchAuthenticateWithGoogle =
  (): ThunkAction<any, RootState, any, any> => (dispatcher, getState) => {
    const { di } = getState()

    dispatcher(fetching())

    return di.AuthRepository.authenticate().then(response => {
      if (response.authenticated) {
        dispatcher(authenticate({ user: response.user }))

        dispatcher(
          actions.notifications.create({
            message: "auth/login",
            type: "success",
          })
        )

        closeLoginModal()
      }

      dispatcher(fetchEnd())
    })
  }

export const fetchIsAuthenticated =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di } = getState()

    dispatcher(fetching())

    return di.AuthRepository.isAuthenticated().then(response => {
      if (response.authenticated) {
        dispatcher(authenticate({ user: response.user }))
      }
      dispatcher(fetchEnd())

      return { authenticated: response.authenticated }
    })
  }

export const fetchLogout =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di } = getState()

    dispatcher(fetching())

    dispatcher(
      actions.notifications.create({
        message: "auth/logout",
        type: "success",
      })
    )

    navigate(-1)

    di.AuthRepository.logout()

    dispatcher(logout())
    dispatcher(fetchEnd())
    // return Models.logout().then(() => {
    //   dispatcher(fetchEnd())
    //   dispatcher(logout())
    //   dispatcher(
    //     actions.snack.create({
    //       message: "Vous êtes bien déconnecté",
    //       type: "success",
    //     })
    //   )
    //   dispatcher(actions.likes.reset())
    // })
  }
