import React from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useLocation, navigate } from "@reach/router"
import { connector, ContainerProps } from "./containers/LogoutModal.container"
import { Button } from "../Button/Button"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { closeLogoutModal } from "../../utils/authModal"

type Props = {
  onLogout: () => void
}

export const LogoutModalWrapper: React.FC<Props> = props => {
  const location = useLocation()
  const isOpen = location.hash.includes("logout=true")

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => false}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-8">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div
                      className="text-xl font-display leading-6 font-medium text-slate-900"
                      id="modal-headline"
                    >
                      <FormattedMessage id="logout/modal/title" />
                    </div>

                    <div className="mt-2">
                      <p className="text-slate-500">
                        <FormattedMessage id="logout/modal/description" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:flex">
                  <Button
                    className="sm:mb-0 w-full sm:w-auto mb-2 sm:mr-2"
                    onClick={props.onLogout}
                  >
                    <FormattedMessage id="logout/modal/submit" />
                  </Button>

                  <Button
                    className="w-full sm:w-auto"
                    theme={"outline"}
                    onClick={() => closeLogoutModal()}
                  >
                    <FormattedMessage id="logout/modal/cancel" />
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const LogoutModalContainer: React.FC<ContainerProps> = props => (
  <LogoutModalWrapper {...props} />
)

export const LogoutModal = connector(LogoutModalContainer)
